/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description, lang, meta, title, type, url, image,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            siteUrl
          }
        }
      }
    `,
  );
  const metaType = type || 'website';
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetdata.title;
  const metaTitleTemplate = title ? '' : `%s | ${site.siteMetadata.title}`;
  const metaArray = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: metaTitle,
    },
    {
      property: 'og:site_name',
      content: site.siteMetadata.name,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: metaType,
    },

    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:title',
      content: metaTitle,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ];

  if (url) metaArray.push({ property: 'og:url', content: `${site.siteMetadata.siteUrl}${url}` });
  if (image) metaArray.push({ property: 'og:image', content: image });
  if (image) metaArray.push({ property: 'og:secure_url', content: image });
  if (image) metaArray.push({ property: 'og:image:width', content: '1280' });
  if (image) metaArray.push({ property: 'og:image:height', content: '640' });
  if (image) metaArray.push({ property: 'twitter:image', content: image });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitleTemplate}
      meta={metaArray.concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  type: '',
  url: '',
  image: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
